import styled from "styled-components";

export const IndustryStandardsContainer = styled.div`
  min-height: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: justify;

  background: #ffffff;
  padding: 50px 0;

  @media screen and (max-width: 768px) {
    min-height: 800px;
  }

  @media screen and (max-width: 768px) {
    min-height: 600px;
  }

`

export const IndustryStandardsWrapper = styled.div`
    max-width: 1100px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    grid-gap: 16px;
    padding: 20px 80px;

  img {
    //width: 200px;
    //height: 200px;
    margin: auto;
    display: block;
  }

    @media screen and (max-width: 1000px) {
        grid-template-columns: 1fr 1fr 1fr;
        padding: 30px 80px;

      img {
        width: 100px;
        height: 100px;
        margin: auto;
        display: block;
      }
    }

    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr 1fr;
        //padding: 10 30px;
        padding: 30px 60px;

      img {
        width: 100px;
        height: 100px;
        margin: auto;
        display: block;
      }
    }

`

export const IndustryStandardsCard = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  border-radius: 50px;
  max-height: 1400px;
  padding: 10px;
  box-shadow: 0 1px 3px rgba(255, 253, 253, 0.2);
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.0);
  }

`

export const IndustryStandardsIcon2 = styled.img`
    height: 160px;
    width: 200px;
    margin-bottom: 40px;
    
`

export const IndustryStandardsIcon = styled.img`
    height: 150px;
    width: 150px;
    margin-bottom: 40px;
    
`

export const IndustryStandardsH1 = styled.h1`
  font-size: 2.5rem;
  color: #000000;
  margin-bottom: 64px;

  @media screen and (max-width: 480px) {
    font-size: 2rem;
  }

`

export const IndustryStandardsH2 = styled.h2`
    font-size: 1.2rem;
    margin-bottom: 10px;
    font-weight: 900;
    
`

export const IndustryStandardsP = styled.p`
    font-size: 1rem;
    text-align: justify;
    display: inline-block;
    word-break: break-all;

`
