import React from "react";
import {IndustryStandardsContainer, IndustryStandardsH1, IndustryStandardsWrapper, IndustryStandardsCard, IndustryStandardsIcon, IndustryStandardsIcon2} from "./IndustryStandardsElements";
import cis from "../../images/cis.png"
import niap from "../../images/niap.png"
import owasp from "../../images/owasp.png"
import nist from "../../images/nist.png"
import mitre from "../../images/mitre.png"
import gdpr from "../../images/gdpr.png"


const IndustryStandards = () => {
  return (
    <IndustryStandardsContainer id="industryStandards">
      <IndustryStandardsH1>Industry Standards</IndustryStandardsH1>
      <IndustryStandardsWrapper>
        <IndustryStandardsCard>
            <IndustryStandardsIcon src={owasp} />
        </IndustryStandardsCard>

        <IndustryStandardsCard>
            <IndustryStandardsIcon2 src={mitre} />
        </IndustryStandardsCard>

          <IndustryStandardsCard>
              <IndustryStandardsIcon2 src={nist} />
          </IndustryStandardsCard>

          <IndustryStandardsCard>
              <IndustryStandardsIcon src={niap} />
          </IndustryStandardsCard>

          <IndustryStandardsCard>
              <IndustryStandardsIcon src={gdpr} />
          </IndustryStandardsCard>

          <IndustryStandardsCard>
              <IndustryStandardsIcon src={cis} />
          </IndustryStandardsCard>

      </IndustryStandardsWrapper>
    </IndustryStandardsContainer>
  );
};

export default IndustryStandards;
