import styled from "styled-components";

export const InfoContainer = styled.div `
    width: 100%;
    min-height: 700px;    
    justify-content: center;  
    display: flex;

`

export const InfoWrapper = styled.div `
    display: flex;
    padding: 80px 40px;
    width: 1200px;
    text-align: justify;
    justify-content: center;
    align-items: center;
   -webkit-hyphens: auto;
   -moz-hyphens: auto;
    word-spacing:-2px;

  -ms-hyphens: auto;
  img {
    width: 280px;
    height: 280px;
    margin: auto;
    display: block;
  }

  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr;
    //padding: 10 30px;
    text-align: justify;
    justify-content: center;
    align-items: center;
    padding: 60px 60px;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    word-spacing:-2px;


    img {
      width: 240px;
      height: 240px;
      margin: auto;
      display: block;
    }
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    //padding: 10 30px;
    justify-content: center;
    word-spacing:-2px;
    padding: 60px 60px;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    
    img {
      width: 220px;
      height: 220px;
      margin: auto;
      display: block;
    }

    
  }
`

export const Title = styled.h1 `
    font-weight: 900;

`

export const Subtitle = styled.h5 `
    color: #54aaff;
    font-weight: 500;
    text-transform: uppercase;

`