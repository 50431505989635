import React from "react";
import {ServicesContainer, ServicesH1, ServicesWrapper, ServicesCard, ServicesH2, ServicesP} from "./ServicesElements";



const Services = () => {
  return (
    <ServicesContainer id="services">
      <ServicesH1>Our Services</ServicesH1>
      <ServicesWrapper>

        <ServicesCard>
          <ServicesH2>Risk Analysis</ServicesH2>
          <ServicesP>
          Employing the most advanced, comprehensive and dynamic approaches and methodologies to identify, assess, and mitigate potential risks to an organization's information systems and assets.          
          </ServicesP>
        </ServicesCard>

        <ServicesCard>
          <ServicesH2>Threat Modelling</ServicesH2>
          <ServicesP>
            Utilizing cutting-edge threat modeling methodologies that enable the identification, evaluation, and prioritization of potential threats within systems, networks, and applications.
          </ServicesP>
        </ServicesCard>

        <ServicesCard>
          <ServicesH2>Penetration Testing</ServicesH2>
          <ServicesP>
          Utilizing state-of-the-art methodologies, approaches and tools to simulate cyber-attacks on networks, applications, and physical or digital infrastructures to identify vulnerabilities and weaknesses. 
          </ServicesP>
        </ServicesCard>
      
        <ServicesCard>
          <ServicesH2>Security Assurance Evaluation</ServicesH2>
          <ServicesP>
          Employ cutting-edge methodologies and practices to assess the security posture, effectiveness and overall security level of systems, applications, and other digital assets.            
          </ServicesP>
        </ServicesCard>

        <ServicesCard>
          <ServicesH2>Countermeasure Effectiveness Evaluation</ServicesH2>
          <ServicesP>
            Utilizing cutting-edge and multi-faceted approaches to assess the efficacy of cybersecurity measures in protecting systems, data and infrastructures from various threats.
          </ServicesP>
        </ServicesCard>

        <ServicesCard>
          <ServicesH2>Vulnerability Assessment</ServicesH2>
          <ServicesP>
          Employing the most advanced, comprehensive methodologies and tools to identify and evaluate security vulnerabilities and potential weaknesses in software, networks, and embeded systems.
          </ServicesP>
        </ServicesCard>
        
      </ServicesWrapper>
    </ServicesContainer>
  );
};

export default Services;
