import React from "react";
import {
  FooterContainer,
  FooterH2,
  FooterH1,
  FooterH3,
  FooterH4,
  FooterWrapper,
} from "./FooterElements";
import "./../../App.css";

const Footer = () => {
  return (
    <FooterContainer id="contact">
      <FooterWrapper className="container py-5">
        <div className="row gx-3 px-4 gy-4">
          
          <div className="col-lg-3 col-sm-6">
            <FooterH1>Contact Us</FooterH1>
            <FooterH4>
              Address:</FooterH4>
            <FooterH2>12 Antigonis St.,
              Kifisia</FooterH2>
              <FooterH2>14562 Greece</FooterH2>
            <FooterH4>Telephone:</FooterH4>
            <FooterH2>+30 211 1169613</FooterH2>
            <FooterH4>Email:</FooterH4>
            <FooterH2>info[at]enumasecurity.com</FooterH2>
            <FooterH4>Social:</FooterH4>
            <div className="flex-container">
              <div class="item">
                <a href="https://www.linkedin.com/company/enuma-cyber-security/">
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-linkedin" viewBox="0 0 16 16" color="#54aaff">
                  <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"/>
                </svg>
                </a>
              </div>



            </div>

          </div>



          
        </div>
        <div className="row">
          <div className="col-12 mt-5 ">
            <h1 className="logo-text white-text center-align">enūma</h1>
            <FooterH3>© Copyright 20[0-9]&#123;2&#125; | Enūma Cyber Security L.P | All Rights and Lefts Reserved </FooterH3>
          </div>
        </div>
      </FooterWrapper>
    </FooterContainer>
  );
};

export default Footer;
