import React from "react";
import {CustomersContainer, CustomersH1, CustomersWrapper, CustomersCard, CustomersIcon} from "./CustomersElements";

import sopra_steria from "../../images/sopra_steria.png"
import qnr from "../../images/Q&R.png"
import moveo from "../../images/moveo.png"
import uprc from "../../images/uprc.png"


const Customers = () => {
  return (
    <CustomersContainer id="customers">
      <CustomersH1>Customers & Partners</CustomersH1>
      <CustomersWrapper>
        <CustomersCard>
            <CustomersIcon src={sopra_steria} />
        </CustomersCard>

        <CustomersCard>
            <CustomersIcon src={moveo} />
        </CustomersCard>

        <CustomersCard>
            <CustomersIcon src={qnr} />
        </CustomersCard>

        <CustomersCard>
            <CustomersIcon src={uprc} />
        </CustomersCard>
        
      </CustomersWrapper>
    </CustomersContainer>
  );
};

export default Customers;
