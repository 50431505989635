import styled from "styled-components";

export const FooterContainer = styled.div`
  padding-top: 30px;
  padding-bottom: 15px;
  background: linear-gradient(#020202, #030303);
   
  
  .flex-container {
    display: flex;
    width: 100%;
  
  }

  .item {
    text-align: left;
    font-size: 5px;
    min-height: 5px;
    width: 30px;
    margin: 0;
  }
  
  
`

export const FooterWrapper = styled.div`
    max-width: 900px;
 
`

export const FooterH2 = styled.h2`
  font-size: 1rem;
  color: #54aaff;
  font-weight: 100;

`

export const FooterH4 = styled.h2`
  font-size: 1rem;
  color: #ffffff;
  font-weight: 100;

`

export const FooterH1 = styled.h1`
    font-size: 1.1rem;
    color: white;
    font-weight: 200;
    margin-bottom: 30px;

`

export const FooterH3 = styled.h3`
    font-size: 0.8rem;
    color: white;
    font-weight: 200;
    margin-bottom: 30px;
    align-items: center;
    text-align:center;

  @media screen and (max-width: 768px) {
    font-size: 0.6rem;
    color: white;
    font-weight: 200;
    margin-bottom: 30px;
    align-items: center;
    text-align:center;
  }

`

export const FooterLink = styled.a`
    font-size: 0.9rem;
    text-decoration: none;
    color: white;
    display: flex;
    transition: all 0.3s;

    &:hover {
        color: #54aaff;
    }
  

`