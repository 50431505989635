import React from "react";
import {
  HeroContainer,
  HeroBg,
  HeroContent
} from "./HeroElements";
import Video from "../../videos/bgvideo.mp4";
import "../../App.css";

const HeroSection = () => {

  return (

    <HeroContainer id="home">
      <HeroBg

              dangerouslySetInnerHTML={{
                  __html: `<video className="app__backgroundVideo" autoplay loop muted playsinline>
                  <source src=${Video} type="video/mp4" />
                  Your browser does not support the video tag.
                 </video>`,
              }}

      />
      <HeroContent>

        <h1 className="h1-hero">Cyber Security Consulting & Research Services</h1>
        <p className="hero-text">
          
        </p>
        
      </HeroContent>
    </HeroContainer>
  );
};

export default HeroSection;
