import React from 'react'
import {SidebarContainer, Icon, CloseIcon, SidebarWrapper, SidebarMenu, SidebarLink} from './SidebarElements'

export const Sidebar = ({isOpen, toggle}) => {
    return (
        <SidebarContainer isOpen={isOpen} onClick={toggle}>
            <Icon onClick={toggle}>
                <CloseIcon />
            </Icon>
            <SidebarWrapper>
                <SidebarMenu>
                    <SidebarLink to="home" onClick={toggle}>
                        Home
                    </SidebarLink>
                    <SidebarLink to="mission" onClick={toggle}>
                        Mission
                    </SidebarLink>
                    <SidebarLink to="research" onClick={toggle}>
                        Research
                    </SidebarLink>
                    <SidebarLink to="services" onClick={toggle}>
                        Services
                    </SidebarLink>
                     <SidebarLink to="customers" onClick={toggle}>
                        Partners
                    </SidebarLink>
                    <SidebarLink to="industryStandards" onClick={toggle}>
                        Industry Standards
                    </SidebarLink>
                    <SidebarLink to="contact" onClick={toggle}>
                        Contact Us
                    </SidebarLink>
                    
                </SidebarMenu>
               
            </SidebarWrapper>
        </SidebarContainer>
    )
}