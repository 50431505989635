import styled from "styled-components";

export const CustomersContainer = styled.div`
  min-height: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: justify;

  background: #ffffff;
  padding: 50px 0;

  @media screen and (max-width: 768px) {
    min-height: 800px;
  }

  @media screen and (max-width: 768px) {
    min-height: 600px;
  }

`

export const CustomersWrapper = styled.div`
    max-width: 1100px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    grid-gap: 9px;
   //padding: 20px 80px;
  img {
    width: 280px;
    height: 70px;
    margin: auto;
    display: block;
  }

    @media screen and (max-width: 1000px) {
        grid-template-columns: 1fr 1fr;
        padding: 20px 80px;
      img {
        width: 200px;
        height: 80px;
        margin: auto;
        display: block;
      }
    }

    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
        //padding: 10 30px;
        padding: 20px 60px;
      img {
        width: 200px;
        height: 50px;
        margin: auto;
        display: block;
      }
      
    }

`

export const CustomersCard = styled.div`
  background: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  border-radius: 10px;
  max-height: 1400px;
  padding: 30px;
  box-shadow: 0 1px 3px rgb(255, 255, 255);
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.0);
  }

`

export const CustomersIcon = styled.img`
    height: 80px;
    width: 240px;
    margin-bottom: 40px;
    
`

export const CustomersH1 = styled.h1`
  font-size: 2.5rem;
  color: #020202;
  margin-bottom: 64px;

  @media screen and (max-width: 480px) {
    font-size: 2rem;
  }

`

export const CustomersH2 = styled.h2`
    font-size: 1.2rem;
    margin-bottom: 10px;
    font-weight: 900;
    
`

export const CustomersP = styled.p`
    font-size: 1rem;
    text-align: justify;
    display: inline-block;
    word-break: break-all;

`
