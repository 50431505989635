import React, { useState } from "react";
import HeroSection from "../components/heroSection";
import InfoSection from "../components/infoSection";
import { Navbar } from "./../components/navbar";
import { Sidebar } from "./../components/sidebar";

import Image1 from "../images/cyber-eye.png";
import Image2 from "../images/cyber-brain.png";
import InfoSectionLight from "../components/infoSectionLight";
import Services from "../components/services";
import Standards from "../components/industryStandardsSection";
import Footer from "../components/footer";
import Customers from "../components/customersSection";

export const Home = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <HeroSection />
      <InfoSection
        image={Image1}
        id="mission"
        subtitle="Mission"
        text="The mission of enūma cybersecurity is to conduct in-depth research and analysis of various aspects of cybersecurity in order to understand and mitigate emerging threats, vulnerabilities, and risks in computer systems, networks, and digital infrastructures. Enūma aims to stay at the forefront of the rapidly evolving cybersecurity landscape, by providing valuable insights and solutions to assist and protect organizations and governments in identifying, managing, and mitigating risks related to information security and privacy."
        
      />
      
      <InfoSectionLight
        image={Image2}
        id="research"
        subtitle="Research"
        text="Enūma cybersecurity aims to to support organizations and institutions in recognizing, handling, and reducing risks associated with information security and privacy.
        The primary research objective of enūma cybersecurity is to develop state-of-the-art techniques and methods to protect sensitive data, ensure the integrity and availability of systems, networks and operations in order to safeguard them against emerging or imminent cyber threats."
        btnText="Explore"
      />

        <Services />
        <Standards />
        <Customers />
        <Footer />
    </>
  );
};
